import {
  StepBoxWrapper,
  TroubleshootWapper,
  TroubleshootTitleWrapper,
  TroubleshootDetailWrapper,
} from './elements';
import { sanitize } from 'dompurify';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';

type StepBoxProps = {
  steps: StepBoxItem[];
  troubleshoot: Troubleshoot;
  environment: Environment;
  infoText: string;
  lang: 'en' | 'ja';
};

export type StepBoxItem = {
  title: string;
  subTitle?: string;
  images: {
    src: string;
    description?: string;
  }[];
};

type Environment = {
  title: string;
  bullets: {
    title: string;
    list: string[];
  }[];
};

type Troubleshoot = {
  headerTitle: string;
  subTitle: string;
  question: string;
  answer: string;
  images: {
    src: string;
    description: string;
  }[];
};

const StepBox = ({
  steps,
  troubleshoot,
  environment,
  infoText,
  lang,
}: StepBoxProps) => {
  const navigate = useNavigate();
  const onClickBack = () => {
    if (lang === 'en') {
      navigate('/en');
    }

    if (lang === 'ja') {
      navigate('');
    }
    window.location.reload();
  };

  return (
    <>
      {steps.map((item, index) => (
        <StepBoxWrapper className="step_box_wrapper" key={index}>
          <div className="title">
            <h1 dangerouslySetInnerHTML={{ __html: sanitize(item.title) }} />
          </div>
          {item?.subTitle && (
            <div className="sub_title">
              <p
                dangerouslySetInnerHTML={{ __html: sanitize(item.subTitle) }}
              />
            </div>
          )}
          <section className="scrolling_wrapper">
            {item.images.map((image, imgIndex) => (
              <div className="details" key={`image-${index}-${imgIndex}`}>
                <div className="images">
                  <img
                    src={image.src}
                    alt={`step ${index + 1}.${imgIndex + 1}`}
                  />
                  {imgIndex !== item.images.length - 1 && <PlayArrowIcon />}
                </div>
                <div
                  className="image_detail"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(image.description),
                  }}
                />
              </div>
            ))}
          </section>
        </StepBoxWrapper>
      ))}

      <TroubleshootWapper className="troubleshoot_wrapper">
        <TroubleshootTitleWrapper className="troubleshoot_title_wrapper">
          <div className="title">
            <h1
              dangerouslySetInnerHTML={{
                __html: sanitize(troubleshoot.headerTitle),
              }}
            />
          </div>
        </TroubleshootTitleWrapper>

        <TroubleshootDetailWrapper>
          <StepBoxWrapper>
            <ul className="qa_list">
              <li>
                <span>Q</span>
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(troubleshoot.question),
                  }}
                />
              </li>
              <li className="answer">
                <span>A</span>
                <div
                  className="detail"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(troubleshoot.answer),
                  }}
                />
              </li>
            </ul>

            <div className="title">
              <h1>{troubleshoot.subTitle}</h1>
            </div>

            <section className="scrolling_wrapper">
              {troubleshoot.images.map((image, imgIndex) => (
                <div className="details" key={`troubleshoot-${imgIndex}`}>
                  <div className="images">
                    <img src={image.src} alt={`troubleshoot ${imgIndex + 1}`} />
                    {imgIndex !== troubleshoot.images.length - 1 && (
                      <PlayArrowIcon />
                    )}
                  </div>
                  <div
                    className="image_detail"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(image.description),
                    }}
                  />
                </div>
              ))}
            </section>
          </StepBoxWrapper>
        </TroubleshootDetailWrapper>
      </TroubleshootWapper>

      <StepBoxWrapper>
        <div className="title">
          <h1>{environment.title}</h1>
        </div>
        <div className="environment">
          {environment.bullets.map((bullet, bulletIndex) => (
            <div key={`environment-${bulletIndex}`}>
              <h2>{bullet.title}</h2>
              <ul
                style={{
                  marginBottom:
                    environment.bullets.length - 1 === bulletIndex
                      ? '0'
                      : '20px',
                }}>
                {bullet.list.map((listItem, listIndex) => (
                  <li key={`environment-li-item-${bulletIndex}-${listIndex}`}>
                    {listItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </StepBoxWrapper>
      <div className="info_btn" onClick={onClickBack}>
        {infoText}
      </div>
    </>
  );
};

export default StepBox;
