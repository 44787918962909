import { Link } from 'react-router-dom';
import words from '../../constants/words';
import { StepBox } from './StepBox';
import { StepBoxItem } from './StepBox/StepBox';
import { Banner, HowToUseWrapper } from './elements';
import { pictures } from './images';

type HowToUseProps = {
  lang: 'ja' | 'en';
};
const HowToUse = ({ lang }: HowToUseProps) => {
  const { bannerImgs,step1Imgs, step2Imgs, step3Imgs, step4Imgs, troubleshootImgs } =
    pictures[lang];
  const howToUse = words.howToUsePage;

  const steps: StepBoxItem[] = [
    {
      title: howToUse.step1.title,
      images: [
        {
          src: step1Imgs.one,
          description: howToUse.step1.images[0].description,
        },
        {
          src: step1Imgs.two,
          description: howToUse.step1.images[1].description,
        },
        {
          src: step1Imgs.three,
          description: howToUse.step1.images[2].description,
        },
      ],
    },
    {
      title: howToUse.step2.title,
      subTitle: howToUse.step2.subTitle,
      images: [
        {
          src: step2Imgs.one,
          description: howToUse.step2.images[0].description,
        },
        {
          src: step2Imgs.two,
          description: howToUse.step2.images[1].description,
        },
      ],
    },
    {
      title: howToUse.step3.title,
      images: [
        {
          src: step3Imgs.one,
          description: howToUse.step3.images[0].description,
        },
        {
          src: step3Imgs.two,
          description: howToUse.step3.images[1].description,
        },
        {
          src: step3Imgs.three,
          description: howToUse.step3.images[2].description,
        },
        {
          src: step3Imgs.four,
          description: howToUse.step3.images[3].description,
        },
      ],
    },
    {
      title: howToUse.step4.title,
      images: [
        {
          src: step4Imgs.one,
          description: howToUse.step4.images[0].description,
        },
        {
          src: step4Imgs.two,
          description: howToUse.step4.images[1].description,
        },
        {
          src: step4Imgs.three,
          description: howToUse.step4.images[2].description,
        },
        {
          src: step4Imgs.four,
          description: howToUse.step4.images[3].description,
        },
        {
          src: step4Imgs.five,
          description: howToUse.step4.images[4].description,
        },
      ],
    },
  ];

  const troubleshoot = {
    ...howToUse.troubleshoot,
    images: [
      {
        src: troubleshootImgs.one,
        description: howToUse.troubleshoot.images[0].description,
      },
      {
        src: troubleshootImgs.two,
        description: howToUse.troubleshoot.images[1].description,
      },
      {
        src: troubleshootImgs.three,
        description: howToUse.troubleshoot.images[2].description,
      },
    ],
  };

  return (
    <HowToUseWrapper>
      <Banner>
        <Link to={'https://youtu.be/JBzeSkbHFTc'} target='_blank'>
          <img src={bannerImgs.tablet} className="tablet" />
          <img src={bannerImgs.mobile} className="mobile" />
        </Link>
      </Banner>
      <StepBox
        steps={steps}
        troubleshoot={troubleshoot}
        environment={words.howToUsePage.environment}
        infoText={words.howToUsePage.returnToText}
        lang={lang}
      />
    </HowToUseWrapper>
  );
};

export default HowToUse;
